'use strict';

/*
exported
companiesSelectorDirective
 */

function companiesSelectorDirective($q, UsersRestangular) {
  return {
    restrict: 'AE',
    require: 'ngModel',
    templateUrl: 'views/companies/companies-selector.directive.html',
    scope: {
      ngModel: '=ngModel',
      showLabel: '=showLabel',
      disabled: '=disabled',
      label: '@',
      showOnlySuppliers: '@',
      multiSelector: '<',
    },
    link: function(scope, iElement, iAttrs, ngModelCtrl) {
      // Hack for ui-select to update model
      scope.multiCompanies = scope.ngModel;
      scope.selected = {};
      scope.displayLabel = scope.showLabel === undefined ? true : scope.showLabel;
      var query = {};
      if (scope.showOnlySuppliers){
        query.isSupplier = true;
      }
      UsersRestangular
        .all('companies')
        .getList(query)
        .then(function(companies) {
          scope.companies = companies;
        });

      // End of init
      ngModelCtrl.$validators.required = function(modelValue) {
        if(scope.multiSelector && Array.isArray(modelValue)){
          return !!(modelValue.length);
        }
        return !!(modelValue && modelValue._id);
      };

      scope.loadCompanies = function(query) {
        var deferred = $q.defer();

        if (!query) {
          deferred.resolve([]);
          return deferred.promise;
        }
      
        var searchQuery = {search: query};
        if (scope.showOnlySuppliers){
          searchQuery.isSupplier = true;
        }
      
        UsersRestangular
          .all('companies')
          .getList(searchQuery)
          .then(function(companies) {
            // Adding a 'text' field to each company object that holds the value of company.name
            // it is used by the tags-input directive to display the company name
            companies.forEach(function(company) {
              company.text = company.name;
            });
            deferred.resolve(companies);
          }, function(error) {
            deferred.reject(error);
          });
      
        return deferred.promise;
      };
      
      // From model to view
      ngModelCtrl.$formatters.push(function(modelValue) {
        return {company: modelValue};
      });

      // From view to model
      ngModelCtrl.$parsers.push(function(viewValue) {
        return viewValue.company;
      });

      // Render view
      ngModelCtrl.$render = function() {
        if (ngModelCtrl.$viewValue.company && !ngModelCtrl.$viewValue.name) {
          var company = ngModelCtrl.$viewValue.company;

          UsersRestangular
            .one('companies', company._id)
            .get()
            .then(function(company) {
              scope.selected.company = company;
            })
            .catch(function() {
              scope.selected.company = null;
            });
        } else {
          scope.selected.company = ngModelCtrl.$viewValue.company;
        }
      };

      // Update model
      scope.updateCompany = function(company) {
        ngModelCtrl.$setViewValue({company: company});
      };

      scope.addToMultiCompanies = function(company) {         
        scope.multiCompanies.push(company);
        ngModelCtrl.$setViewValue({company: scope.multiCompanies});
      };

      scope.removeFromMultiCompanies = function(company) {
        scope.multiCompanies = scope.multiCompanies.filter(item => item.id !== company.id);
        ngModelCtrl.$setViewValue({company: scope.multiCompanies});
      };

      // Refresh companies list on search
      scope.refreshCompaniesList = function(search) {
        if (!search) {
          return;
        }

        var refreshQuery = {search: search, limit: 0};
        if (scope.showOnlySuppliers){
          refreshQuery.isSupplier = true;
        }

        UsersRestangular
          .all('companies')
          .getList(refreshQuery)
          .then(function(companies) {
            scope.companies = companies;
          });
      };
    }
  };
}

'use strict';

/* exported
signAgreementViewModalService
 */

function signAgreementViewModalService($uibModal, $state, $sessionStorage) {
  /* jshint validthis: true */
  this.show = function(company) {

    if($sessionStorage.signAgreementModalIgnored) {
      return;
    }

    $uibModal.open({
      templateUrl: 'views/companies/sign-agreement.html',
      controller: 'CompanySignAgreementCtrl',
      resolve: {
        company: [function() {
          return company;
        }]
      },
      backdrop: 'static',
      keyboard  : false
    })
      .result.then(function () {
        // ...
      }, function () {
        // ...
      });
  };
}
